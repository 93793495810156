<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <div class="page-title-right d-flex">
            <div class="me-2 d-flex">
              <label class="col-form-label me-2">Year</label>
              <div style="width: 150px">
                <v-select
                  label="name"
                  v-model="year"
                  :options="years"
                  :selectable="(options) => isSelectableYear(options)"
                  :clearable="false"
                  :reduce="(name) => name.id"
                  @option:selected="getPlansByYear($event)"
                >
                </v-select>
              </div>
            </div>
            <label class="col-form-label me-2">Month</label>
            <div style="width: 165px">
              <v-select
                label="name"
                v-model="month"
                :options="months"
                :clearable="false"
                :selectable="(options) => isSelectableMonth(options)"
                :reduce="(name) => name.id"
                @option:selected="getPlansByMonth($event)"
              >
              </v-select>
            </div>

            <div @click="refreshData" class="icon-css">
              <i style="color: #4a81d4" class="fe-rotate-cw"></i>
            </div>
          </div>
          <h4 class="page-title">Orientation Assessment Schedule</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
          
          <div class="mb-4">  
             <!-- advance serach -->
             <div class="d-flex">
                            <!-- {{ programs }} -->
                  <div class="d-flex flex-column me-2">
                      <label class="col-form-label">Program</label>
                      <div style="width: 250px">
                          <v-select label="name" v-model="program" :options="programs" :clearable="false"
                              :selectable="(options) => program.short_name != options.short_name "
                              @option:selected="findBatch_programFilter(program)">
                          </v-select>
                      </div>
                  </div>
                  <!-- {{ batchs }} -->
                  <div class="d-flex flex-column me-2">
                      <label class="col-form-label">Batch</label>
                      <div style="width: 180px">
                          <v-select label="batch_no" v-model="batch" :options="batchs" :clearable="false"
                          :selectable="(options) =>  batch.id != options.id "
                          @option:selected="FilterData(program, batch)"
                          >
                          </v-select>
                      </div>
                  </div>
                  
                  <div class="d-flex mt-4">
                      <!-- <div class="col-form-label d-none">hfhd</div> -->
                      <div @click="refreshData()" class="icon-css">
                          <i style="color: #4a81d4" class="fe-rotate-cw"></i>
                      </div>
                  </div> 
              </div>
              <!--end advance serach -->

            <div class="d-flex justify-content-end">
              <router-link :to="{ name: 'orientation-assessment-create' }">
                <button
                  v-show="!loading"
                  type="button"
                  class="btn btn-sm btn-blue waves-effect waves-light"
                  v-if="hasPermissions(['schedule-orientation-assessment-create'])"
                >
                  <i class="mdi mdi-plus-circle"></i> Add Record
                </button>
              </router-link>
            </div>
          </div>  
            <place-holder v-if="loading"></place-holder>
            <div class="table-responsive" v-if="!loading">
              <table
                class="table table-striped dt-responsive w-100 mb-3"
                id="orientation-assessment-list-datatable"
                v-if="!loading"
              >
                <thead v-if="!loading">
                  <tr>
                    <th>No.</th>
                    <th>Assessment Code</th>
                    <th>Program Name</th>
                    <th>Batch</th>
                    <th>From Date</th>
                    <th>To Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody v-if="!loading">
                  <tr v-for="(item, index) in assessment" :key="index">
                    <td>{{ ++index }}</td>
                    <td>{{ item.code }}</td>
                    <td>{{ item.program_name }}</td>
                    <td>{{ item.program_batch_no }}</td>
                    <td>{{ item.from_date }}</td>
                    <td>{{ item.to_date }}</td>
                    <td>
                      <span>
                        <router-link
                          class="action-icon"
                          :to="{
                            name: 'orientation-assessment-update',
                            params: { id: item.id },
                          }"
                        >
                          <i class="mdi mdi-square-edit-outline"></i>
                        </router-link>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <!-- <div class="text-center" v-if="assessment.length == 0">No data avaliable in Orientation Assessment.</div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
//Datatable Program Allocation
import "datatables.net/js/jquery.dataTables.min.js";

import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
import "datatables.net-responsive-bs5/js/responsive.bootstrap5.min.js";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import $ from "jquery";
import axios from "axios";
import { useToast } from "vue-toastification";

//Mixins
import userHasPermissions from "../../../mixins/userHasPermissions";

export default {
  components: {
    vSelect,
  },
  mixins: [userHasPermissions],
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      assessment: [],
      month: "",
      months: [],
      year: "",
      years: [],
      yearId: "",
      programs: [],
      batchs : [],
      program : "",
      batch : "",
      loading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlHRIS: process.env.VUE_APP_COURSIA_BASE_URL,
    };
  },
  methods: {
    isSelectableYear(options) {
      if(!this.year) return true;
      return this.year != options.id;
    },
    isSelectableMonth(options) {
      if(!this.month) return true;
      return this.month != options.id;
    },
    async getAllProgramAllocation() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(`${this.baseUrl}admin/v2/assessment?type=ORI`)
        .then((response) => {
          this.assessment = response.data.data;
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.toast.error("Something Went Wrong!");
        });
      $("#orientation-assessment-list-datatable").DataTable();
    },

    async getBudgetYear() {
      this.loading = true;
      await axios
        .get(`${this.baseUrl}admin/v2/scheduleManagement/getYear`)
        .then((response) => {
          this.years = response.data.data;
          this.loading = false;
        });
    },

    async getPlansByYear(value) {
      this.yearId = value.id;
      this.month = "";
      this.training_plan_status = "";
      this.loading = true;
      this.$Progress.start();
      await this.getAllMonths(this.yearId);
    },

    async getPlansByMonth(value) {
      if (this.yearId) {
        this.clearFilter()
        this.loading = true;
        this.$Progress.start();
        axios
          .get(
            `${this.baseUrl}admin/v2/scheduleManagement/orientationAssessment?budget_year_id=${this.yearId}&month=${value.id}&type=ORI`
          )
          .then((response) => {
            this.assessment = response.data.data;
            console.log(this.assessment);
            this.loading = false;
            this.$Progress.finish();
               if (this.assessment.length === 0) {
                    this.$nextTick(() => {
                        $("#orientation-assessment-list-datatable").DataTable();
                    });
                }
          });
      } else {
        this.month = "";
        this.toast.error("Please Choose Budget Year First!");
      }
  
    },

    async getAllMonths(year_id) {
      axios
        .get(`${this.baseUrl}admin/v1/get-months/${year_id}`)
        .then((response) => {
          let obj = response.data.data;
          this.months = Object.keys(obj).map((key) => {
            this.loading = false;
            this.$Progress.finish();
            return { id: key, name: obj[key] };
          });
        });
    },
    clearFilter() {
      $.fn.dataTable.ext.search.pop();
      $("#orientation-assessment-list-datatable").DataTable().draw();
    },
    async findBatch_programFilter(program) {
            this.batch = "";
            this.loading = true;
            await axios
                .get(`${this.baseUrlHRIS}api/program-batches/list/?program_id=${program.id}`)
                .then((response) => {
                    this.batchs = response.data.data;
                    this.loading = false;
                })
                .catch(() => {
                    this.toast.error("Not Found Batch!");
                });
    
                this.clearFilter();
                $.fn.dataTable.ext.search.push(function (settings, data, dataIndex) {
                    const check_program = program?.name == data[2] ? true : false;
                    console.log('checl-prog', check_program);
                    return check_program ? 1 : 0;
                });
                $("#orientation-assessment-list-datatable").DataTable().draw(); 
        },
    FilterData(program, batch) {
            this.clearFilter();
            // alert('reach');
            $.fn.dataTable.ext.search.push(function (settings, data, dataIndex) {
                const check_program = program?.name == data[2] ? true : false;
                const check_batch = batch?.batch_no == data[3] ? true : false;
                
                return (check_program && check_batch) ? true : false;
            });
            $("#orientation-assessment-list-datatable").DataTable().draw(); 
        },
    refreshData() {
          this.month = "";
          this.assessment = [];
          this.year = "";
          this.months = [];
          this.years = [];
          this.yearId = "";
          this.program = "";
          this.batchs = "";
          this.batch = "";
          this.clearFilter();
          this.getBudgetYear();
          this.getAllProgramAllocation();
        },
  },
  created() {
    this.clearFilter();
    this.getBudgetYear();
    this.getAllProgramAllocation();
    if(this.programs.length == 0){
            setTimeout(()=>{
                this.programs = this.$store.getters["odoo/getAllPrograms"];
            }, 1000)
        }
  },
};
</script>
  
  <style>
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #4a81d4;
  border-color: #4a81d4;
}
</style>
  